<template>
  <div class="flex gap-24">
    <div class="flex-1">
      <st-box>
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.prod_mgmt_price_reg_sales_type_title')"
          required
        />
        <radio-group
          name="salesType"
          :options="optionSalesType"
          :rules="{
            required: true
          }"
          :optionProps="{
            size: 'sm',
            align: 'middle'
          }"
          class="flex flex-wrap gap-x-24 mt-12"
          @update:modelValue="handleChangeSalesType"
        />

        <div v-show="salesType === SALES_TYPE.PAYMENT">
          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.prod_mgmt_price_reg_base_price_title')"
            required
            class="mt-32"
          />
          <p class="mt-4 text-on-surface-elevation-4 text-md">
            {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_reg_base_price_msg') }}
          </p>
          <radio-buttons
            v-model="productPrice"
            :options="priceList"
            outline
            name="priceSelect"
            :rules="{
              required: {
                value: true,
                showError: showError,
                message: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_base_price_required_val_msg'
              }
            }"
            listClass="col-span-1 [&>label]:w-full [&>label]:justify-center"
            containerClass="grid grid-cols-7 gap-8 mt-8"
            :breakListNumber="12"
            hasManualInputButton
            @update:modelValue="handleChange"
          />
          <dl
            v-if="productPrice === 'manual'"
            class="flex gap-8 mt-16 p-16 bg-[var(--stds-glob-color-gray40)] rounded-2xl"
          >
            <dt
              class="shrink-0 min-w-100 mt-[1rem] text-md font-medium leading-lg text-on-surface-elevation-1"
            >
              {{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_title'
                )
              }}
            </dt>
            <dd class="flex-1">
              <div class="grid grid-cols-[minmax(0,1fr)_2.8rem_auto] items-center gap-8">
                <div class="relative">
                  <input-text
                    v-model="tempValue"
                    :maxLength="'14'"
                    placeholder="0.00"
                    :countable="false"
                    :clearable="false"
                    :currencyOptions="{ currency: 'USD' }"
                    :caretPosition="Position.RIGHT"
                    containerClass="flex-1 [&>.stds-input-wrapper>.stds-input]:tracking-[0] [&>.stds-input-wrapper>.stds-input]:text-right [&>.stds-input-clear-button]:hidden"
                    @blur="validatePrice"
                  />

                  <input-text
                    :maxLength="'14'"
                    name="inputPrice"
                    :rules="{
                      required: {
                        value: true,
                        showError: false,
                        message:
                          'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_req_val_msg1'
                      },
                      lte: {
                        value: MAX_VALUE_PRICE_INPUT,
                        showError: false
                      },
                      gte: {
                        value: 0.99,
                        message:
                          'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg3',
                        showError: false
                      },
                      regex: {
                        pattern: REGEX,
                        message:
                          'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg2',
                        showError: false
                      },
                      max_length: {
                        value: 9,
                        message:
                          'studio.prj_prod.this_prod.prod_mgmt_price_reg_price_invalid_val_msg5',
                        showError: false
                      }
                    }"
                    containerClass="hidden"
                  />
                </div>

                <span class="text-md leading-sm text-on-surface-elevation-1">{{
                  CurrencyCode.Usd
                }}</span>

                <s-button
                  variant="primary"
                  size="sm"
                  class="!min-w-72"
                  :class="{ 'cursor-not-allowed': !inputPrice }"
                  :isDisabled="!inputPrice"
                  @click="calculatePaymentPrice"
                >
                  {{
                    $t(
                      'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_apply_btn'
                    )
                  }}
                </s-button>

                <div class="flex flex-col">
                  <div
                    class="flex justify-end gap-8 text-xs leading-xs text-on-surface-elevation-3"
                  >
                    <span>{{
                      typeof krPrice === 'number' ? formatPrice(krPrice, CurrencyCode.Krw) : 0
                    }}</span>
                  </div>
                </div>

                <span class="text-xs leading-xs text-on-surface-elevation-3 text-center">KRW</span>

                <div
                  v-if="errorInput && JSON.parse(errorInput).message"
                  class="col-span-2 flex justify-end gap-8 text-xs leading-xs text-on-surface-elevation-3"
                >
                  <st-error-message :errorMessage="errorInput" />
                </div>
              </div>
            </dd>
          </dl>

          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_title')"
            required
            class="mt-32"
          />
          <div class="mt-8">
            <st-table :columns="columns" :data="currencyList">
              <template #row="{ row }">
                <st-tr>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <store-display-price-info
                      :flagImage="flagImages[row.displayCurrencyCode]"
                      :countryName="row.countryName"
                      :displayPriceValue="
                        isFirstTime ? row.displayPriceValue : calculateDisplayPrice(row)
                      "
                      :currencyCode="row.displayCurrencyCode"
                    />
                  </st-td>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <payment-price-info
                      :name="`itemPriceInfos.${row.displayCurrencyCode}`"
                      :currencyCode="
                        row.displayCurrencyCode === CurrencyCode.Krw
                          ? CurrencyCode.Krw
                          : CurrencyCode.Usd
                      "
                      :isDisabled="isDisabled"
                      :defaultPrice="
                        isFirstTime
                          ? row.salesPriceValue
                          : row.displayCurrencyCode !== CurrencyCode.Krw
                            ? productPriceNumber
                            : productPriceNumberKrw
                      "
                    />
                  </st-td>
                </st-tr>
              </template>
            </st-table>
            <div
              v-if="
                showErrorRadioAndInput && JSON.parse(showErrorRadioAndInput).message && showError
              "
              class="col-span-2 flex justify-start gap-8 text-xs leading-xs text-on-surface-elevation-3"
            >
              <st-error-message :errorMessage="showErrorRadioAndInput" />
            </div>
            <div
              v-else-if="showErrorTable && JSON.parse(showErrorTable).message"
              class="col-span-2 flex justify-start gap-8 text-xs leading-xs text-on-surface-elevation-3"
            >
              <st-error-message :errorMessage="showErrorTable" />
            </div>
          </div>
        </div>
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="onSubmit">
          {{
            itemInfo?.verifyPassedPrice?.verifyStatus === PRICE_ITEM_STATUS.PASS
              ? $t('studio.common.live_apply_btn')
              : $t('studio.common.popup_case_save_btn')
          }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        :rightWingTitle="$t('studio.prj_prod.this_prod.prod_mgmt_price_reg_guide1')"
        numberValue="01"
        class="mt-24"
      >
        {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_reg_guide1_1') }}
      </right-wing-item>
      <right-wing-item
        :rightWingTitle="$t('studio.prj_prod.this_prod.prod_mgmt_price_reg_guide2')"
        numberValue="02"
        class="mt-24"
      >
        {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_reg_guide2_1') }}
      </right-wing-item>
    </right-wing>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import {
  useFieldError,
  useFieldValue,
  useForm,
  useSetFieldError,
  useSetFieldValue
} from 'vee-validate';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import {
  createProductSellingPriceApi,
  fetchPriceTemplatesApi,
  fetchProductSellingPriceApi,
  modifyProductSellingPriceApi
} from '@/apis/product-selling-price.api';
import { createReviewRequestApi } from '@/apis/review.api';
import * as ImageAssets from '@/assets/images/selling-price';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import StBox from '@/components/common/st-box.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import PaymentPriceInfo from '@/components/selling-price/payment-price-info.vue';
import StoreDisplayPriceInfo from '@/components/selling-price/store-display-price-info.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioButtons from '@/components/validation/radio-buttons.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { showConfirm } from '@/composables/useDialog';
import { MODE } from '@/constants/common.const';
import { COUNTRY_BY_CURRENCY, currencyCodes } from '@/constants/currency-codes.const';
import { STATUS_CODE } from '@/constants/error.const';
import { PLAN_STATUS } from '@/constants/products.const';
import {
  MAX_VALUE_PRICE_INPUT,
  PRICE_ITEM_STATUS,
  SALES_TYPE
} from '@/constants/selling-price.const';
import { PRODUCT_HOME_PAGE_URL, PRODUCT_SELLING_PRICE_PAGE_URL } from '@/constants/url.const';
import { Confirmation, Position } from '@/enums/common.enum';
import { CurrencyCode, type CurrencyCodeType } from '@/enums/currency-code.enum';
import { RuleNames } from '@/enums/validation.enum';
import { useCurrencyStore } from '@/stores/currency.store';
import useProductStore from '@/stores/product.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type {
  PriceCurrencyType,
  PriceListType
} from '@/types/product-selling-price/product-selling-price.type';
import type { ProductSellingRequest } from '@/types/product-selling-price/product-selling-price-request.type';
import type {
  PriceTemplateResponse,
  ProductSellingPriceResponse
} from '@/types/product-selling-price/product-selling-price-response.type';
import { throwContentError } from '@/utils/api-error.util';
import { redirectTo, showConfirmLeaveDialog } from '@/utils/common.util';
import { formatPrice, getDisplayPrice, getLocalPrice } from '@/utils/currency.util';
import { formatMoney } from '@/utils/string.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  mode: string;
}>();

interface SellingPriceFormValues {
  salesType?: string;
  inputPrice: number;
  referenceCurrencyType: string;
  referenceCurrency: number;
  itemPriceInfos: Record<string, number>;
  priceSelect?: string;
}

const { checkProductPermission } = useApp();

const REGEX = /^[0-9.]+$/;
const REGEX_WITH_COMMA = /^[0-9,.]+$/;
const CURRENCY_FORMAT_REGEX = /^\d{1,3}(,\d{3})*(\.\d{1,2})?$/;

const productStore = useProductStore();
const { product } = storeToRefs(productStore);
const route = useRoute();

const { productId } = route.params;

const { t } = useI18n();

const tempValue = ref('');
const isCheckLeave = ref<boolean>(true);
const isDisabled = ref<boolean>(true);
const isFirstTime = ref<boolean>(true);

const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const showError = ref<boolean>(false);

const { validate, setValues, values, resetForm } = useForm<SellingPriceFormValues>({
  initialValues: {
    salesType: props.mode === MODE.CREATE ? SALES_TYPE.PAYMENT : undefined,
    inputPrice: undefined,
    referenceCurrencyType: '',
    referenceCurrency: 0,
    itemPriceInfos: {},
    priceSelect: undefined
  },
  keepValuesOnUnmount: true
});

const setInputPrice = useSetFieldValue<string>('inputPrice');
const setInputPriceError = useSetFieldError('inputPrice');

const onChangePrice = (value: string) => {
  const match = value.match(REGEX_WITH_COMMA) ? value.match(REGEX_WITH_COMMA)!.length > 0 : false;
  if (match) {
    const price = value.replace(/,/g, '');
    const newPrice = formatMoney(Number(price));
    tempValue.value = newPrice;
    setInputPrice(newPrice.replace(/,/g, ''));
    return;
  }
  if (value === '') {
    setInputPrice('');
    return;
  }
  const errorMsg = generateErrorMsg(
    'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg2',
    RuleNames.REGEX
  );
  setInputPriceError(errorMsg);
};

const onBlurPrice = () => {
  onChangePrice(tempValue.value);
};

const errorsTable = Object.keys(CurrencyCode).map((key: string) => {
  return {
    code: key,
    error: useFieldError(`itemPriceInfos.${CurrencyCode[key as CurrencyCodeType]}`)
  };
});

const showErrorTable = computed(() => {
  const errorRequired = errorsTable.find(
    (it: { code: string; error: ReturnType<typeof useFieldError> }) =>
      it.error.value && JSON.parse(it.error.value).rule === RuleNames.REQUIRED
  );

  if (errorRequired) {
    if (tempPrice.value) {
      return errorRequired.error.value;
    }

    return generateErrorMsg(
      'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_required_val_msg1',
      RuleNames.REQUIRED
    );
  }

  const errorRegex = errorsTable.find(
    (it: { code: string; error: ReturnType<typeof useFieldError> }) =>
      it.error.value && JSON.parse(it.error.value).rule === RuleNames.REGEX
  );

  if (errorRegex) {
    return errorRegex.error.value;
  }

  if (
    values.itemPriceInfos &&
    Object.values(values.itemPriceInfos).includes(0) &&
    showError.value
  ) {
    return generateErrorMsg(
      'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_required_val_msg1',
      RuleNames.REQUIRED
    );
  }

  return undefined;
});

const showErrorRadioAndInput = computed(() => {
  if (errorRadio.value) {
    return generateErrorMsg(
      'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_required_val_msg1',
      RuleNames.REQUIRED
    );
  }

  if (errorInput.value && JSON.parse(errorInput.value).rule === RuleNames.REQUIRED) {
    return generateErrorMsg(
      'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_required_val_msg1',
      RuleNames.REQUIRED
    );
  }

  return undefined;
});

const columns = [
  {
    title: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_header1'
  },
  { title: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_checkout_price_header2' }
];

const tempPrice = ref<number>(0);
const exchangeRates = ref<CurrencyInfoModel[]>();
const productPrice = ref<string | number>();
const currencyList = ref<PriceCurrencyType[]>([]);
const flagImages = ref<Record<string, string>>(ImageAssets);
const optionSalesType = ref<FormOption[]>([
  {
    value: SALES_TYPE.PAYMENT,
    label: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_sales_type_paid_msg'
  },
  {
    value: SALES_TYPE.FREE,
    label: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_sales_type_free_msg'
  }
]);
const priceList = ref<FormOption<string>[]>([]);

const krExchangeInfo = computed(() => {
  return exchangeRates.value?.find((it: CurrencyInfoModel) => it.currencyCode === CurrencyCode.Krw);
});
const productPriceNumber = computed(() => {
  return productPrice.value && productPrice.value !== 'manual'
    ? parseFloat(productPrice.value.toString().split('_')[0])
    : tempPrice.value;
});
const productPriceNumberKrw = computed(() => {
  return productPrice.value && productPrice.value !== 'manual'
    ? parseFloat(productPrice.value.toString().split('_')[1])
    : getDisplayPrice(
      Number(productPriceNumber.value),
      krExchangeInfo.value?.exchangeRate,
      krExchangeInfo.value?.displayDigitsNum,
      krExchangeInfo.value?.currencyCode
    ) || 0;
});

const inputPrice = useFieldValue<number>('inputPrice');
const salesType = useFieldValue<string>('salesType');
const errorInput = useFieldError('inputPrice');
const itemPriceInfos = useFieldValue<Record<string, number>>('itemPriceInfos');
const setItemPriceInfos = useSetFieldValue<Record<string, number>>('itemPriceInfos');
const itemInfo = ref<ProductSellingPriceResponse>();

const errorRadio = useFieldError('priceSelect');

const krPrice = computed(() => {
  return (
    getDisplayPrice(
      Number(inputPrice.value),
      krExchangeInfo.value?.exchangeRate,
      krExchangeInfo.value?.displayDigitsNum,
      krExchangeInfo.value?.currencyCode
    ) || 0
  );
});

const getExchangeInfo = (currencyCode: string) => {
  return exchangeRates.value?.find((rate: CurrencyInfoModel) => rate.currencyCode === currencyCode);
};

const calculatePaymentPrice = () => {
  if (isNaN(inputPrice.value) || errorInput.value) {
    return;
  }

  isDisabled.value = false;
  isFirstTime.value = false;

  if (inputPrice.value === tempPrice.value) {
    currencyCodes.forEach((cur: CurrencyCode) => {
      if (cur !== CurrencyCode.Krw) {
        itemPriceInfos.value[cur] = productPriceNumber.value;
      } else {
        itemPriceInfos.value[cur] = productPriceNumberKrw.value;
      }
    });
  }

  tempPrice.value = inputPrice.value;
};

const calculateDisplayPrice = (row: PriceCurrencyType) => {
  const price =
    Number(itemPriceInfos.value[row.displayCurrencyCode]) > 0
      ? Number(itemPriceInfos.value[row.displayCurrencyCode])
      : 0;

  const exchangeInfo = getExchangeInfo(row.displayCurrencyCode);

  return row.displayCurrencyCode !== CurrencyCode.Krw
    ? getLocalPrice({
      standardPrice: price,
      currencyCode: row.displayCurrencyCode,
      exchangeRate: exchangeInfo?.exchangeRate,
      displayDigitsNum: exchangeInfo?.displayDigitsNum
    })
    : Math.floor(price / 10) * 10;
};

const fetchPriceInfos = () => {
  currencyList.value = currencyCodes.map((code: CurrencyCode) => {
    return {
      countryName: COUNTRY_BY_CURRENCY[code],
      displayCurrencyCode: code,
      displayPriceValue: 0
    };
  });

  const itemPriceInfosTmp = currencyList.value.reduce(
    (acc: Record<string, number | undefined>, curr: PriceCurrencyType) => {
      acc[curr.displayCurrencyCode] = undefined;
      return acc;
    },
    {}
  );

  setValues({
    itemPriceInfos: itemPriceInfosTmp
  });
};

const onSubmit = async () => {
  if (!(await checkProductPermission())) {
    await redirectTo(`${PRODUCT_SELLING_PRICE_PAGE_URL}`);
    return;
  }
  showError.value = true;

  if (values.salesType === SALES_TYPE.PAYMENT) {
    if (values.itemPriceInfos && Object.values(values.itemPriceInfos).includes(0)) {
      await validate();
      return;
    }

    if (showErrorTable.value) {
      return;
    }
  }

  if (props.mode === MODE.CREATE) {
    try {
      const request: ProductSellingRequest = {
        paidYn: values.salesType === SALES_TYPE.PAYMENT ? Confirmation.YES : Confirmation.NO,
        recommendSelectYn: productPrice.value === 'manual' ? Confirmation.NO : Confirmation.YES,
        defaultPrice: productPriceNumber.value,
        priceList: currencyList.value.map((it: PriceCurrencyType) => {
          return {
            displayCurrencyCode: it.displayCurrencyCode,
            salesPrice: itemPriceInfos.value[it.displayCurrencyCode] || 0,
            salesCurrencyCode:
              it.displayCurrencyCode !== CurrencyCode.Krw ? CurrencyCode.Usd : CurrencyCode.Krw,
            displayPrice: calculateDisplayPrice(it)
          };
        })
      };

      if (values.salesType === SALES_TYPE.PAYMENT) {
        await validate();
        if (showErrorTable.value) {
          return;
        }
      }

      const result = await createProductSellingPriceApi(Number(productId), request);

      if (result) {
        isCheckLeave.value = false;
        const dialog = await showConfirm({
          content: t('studio.common.popup_case_d_complete_save'),
          confirmLabel: t('studio.redirect_prod_home_btn'),
          cancelLabel: t('studio.common.popup_case_close_btn'),
          cancelVariant: 'outline'
        });

        if (!dialog) {
          redirectTo(PRODUCT_SELLING_PRICE_PAGE_URL);
        } else { redirectTo(PRODUCT_HOME_PAGE_URL); }
      }
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      if (error.statusCode === STATUS_CODE.PRODUCT_PRICE_ALREADY_EXIST) {
        await throwContentError(
          'studio.prj_prod.this_prod.prod_mgmt_price_reg_x_under_review_pop_msg1'
        );
      }
    }
  } else {
    let priceList: PriceListType[] = [];
    if (values.salesType === SALES_TYPE.PAYMENT) {
      priceList = currencyList.value.map((it: PriceCurrencyType) => {
        return {
          displayCurrencyCode: it.displayCurrencyCode,
          salesPrice: itemPriceInfos.value[it.displayCurrencyCode] || 0,
          salesCurrencyCode:
            it.displayCurrencyCode !== CurrencyCode.Krw ? CurrencyCode.Usd : CurrencyCode.Krw,
          displayPrice: calculateDisplayPrice(it)
        };
      });
    }

    const request: ProductSellingRequest = {
      paidYn: values.salesType === SALES_TYPE.PAYMENT ? Confirmation.YES : Confirmation.NO,
      recommendSelectYn: productPrice.value === 'manual' ? Confirmation.NO : Confirmation.YES,
      defaultPrice:
        values.salesType === SALES_TYPE.PAYMENT && productPriceNumber.value > 0
          ? productPriceNumber.value
          : 0,
      priceList
    };

    if (values.salesType === SALES_TYPE.PAYMENT) {
      await validate();
      if (showErrorTable.value) {
        return;
      }
    }

    if (itemInfo.value?.verifyPassedPrice?.verifyStatus === PRICE_ITEM_STATUS.PASS) {
      const confirm = await showConfirm({
        content: t('studio.prj_prod.this_prod.prod_mgmt_price_edit_adjust_live_price_pop_msg'),
        confirmLabel: t(
          'studio.prj_prod.this_prod.prod_mgmt_price_edit_adjust_live_price_pop_review_req_btn'
        ),
        cancelLabel: t('studio.common.popup_case_cancel_btn'),
        cancelVariant: 'outline'
      });
      if (!confirm) {
        return;
      }
    }

    const result = await modifyProductSellingPriceApi(Number(productId), request);

    if (result?.verifyPassedPrice && result?.notVerifyPassedPrice) {
      if (product.value?.planStatus !== PLAN_STATUS.NONE) {
        const reviewRequest = await createReviewRequestApi({
          productNo: Number(productId),
          verifyType: 'PRICE',
          target: result?.notVerifyPassedPrice?.salesNo.toString()
        });

        if (reviewRequest?.success) {
          isCheckLeave.value = false;
          redirectTo(PRODUCT_SELLING_PRICE_PAGE_URL);
          return;
        }
      }
    }

    if (product.value?.planStatus === PLAN_STATUS.NONE || product.value?.planStatus === PLAN_STATUS.PAGE_OPEN) {
      isCheckLeave.value = false;
      const dialog = await showConfirm({
        content: t('studio.common.popup_case_d_complete_save'),
        confirmLabel: t('studio.redirect_prod_home_btn'),
        cancelLabel: t('studio.common.popup_case_close_btn'),
        cancelVariant: 'outline'
      });

      if (!dialog) {
        redirectTo(PRODUCT_SELLING_PRICE_PAGE_URL);
      } else { redirectTo(PRODUCT_HOME_PAGE_URL); }
      return;
    }

    if (result?.notVerifyPassedPrice) {
      isCheckLeave.value = false;
      redirectTo(PRODUCT_SELLING_PRICE_PAGE_URL);
    }
  }
};

const init = async () => {
  fetchPriceInfos();

  exchangeRates.value = await fetchCurrencyInfos(currencyCodes);

  if (props.mode === MODE.EDIT) {
    itemInfo.value = await fetchProductSellingPriceApi(Number(productId));

    if (itemInfo.value) {
      if (itemInfo.value.verifyPassedPrice && !itemInfo.value.notVerifyPassedPrice) {
        resetForm({
          values: {
            salesType:
              itemInfo.value.verifyPassedPrice?.paidYn === Confirmation.YES
                ? SALES_TYPE.PAYMENT
                : SALES_TYPE.FREE,
            priceSelect: 'manual'
          }
        });
      } else {
        resetForm({
          values: {
            salesType:
              itemInfo.value.notVerifyPassedPrice?.paidYn === Confirmation.YES
                ? SALES_TYPE.PAYMENT
                : SALES_TYPE.FREE,
            priceSelect: 'manual'
          }
        });
      }
    }
  }

  const isVerifiedFree =
    itemInfo.value?.verifyPassedPrice &&
    !itemInfo.value?.notVerifyPassedPrice &&
    itemInfo.value.verifyPassedPrice.paidYn === Confirmation.NO;
  const isNotVerifiedFree =
    itemInfo.value?.notVerifyPassedPrice &&
    itemInfo.value.notVerifyPassedPrice.paidYn === Confirmation.NO;

  const templates = await fetchPriceTemplatesApi(Number(productId));
  if (templates) {
    priceList.value = templates.contents
      .slice(0, 41)
      .map((it: PriceTemplateResponse, index: number) => {
        return {
          label: formatMoney(it.usdSellingPrice),
          value: `${it.usdSellingPrice}_${it.krwPaymentPrice}_${index}`
        };
      });
  }

  if (
    itemInfo.value?.notVerifyPassedPrice?.priceList ||
    itemInfo.value?.verifyPassedPrice?.priceList ||
    itemInfo.value?.notVerifyPassedPrice?.defaultPrice ||
    itemInfo.value?.verifyPassedPrice?.defaultPrice
  ) {
    if (
      itemInfo.value?.notVerifyPassedPrice &&
      itemInfo.value.notVerifyPassedPrice.recommendSelectYn === Confirmation.YES &&
      !isVerifiedFree &&
      !isNotVerifiedFree
    ) {
      productPrice.value = priceList.value.find(
        (it: FormOption<string>) =>
          itemInfo.value?.notVerifyPassedPrice?.defaultPrice.toString() === it.value.split('_')[0]
      )?.value;
    }

    if (
      itemInfo.value?.verifyPassedPrice && !itemInfo.value?.notVerifyPassedPrice &&
      itemInfo.value.verifyPassedPrice.recommendSelectYn === Confirmation.YES &&
      !isVerifiedFree &&
      !isNotVerifiedFree
    ) {
      productPrice.value = priceList.value.find(
        (it: FormOption<string>) =>
          itemInfo.value?.verifyPassedPrice?.defaultPrice.toString() === it.value.split('_')[0]
      )?.value;
    }

    if (!productPrice.value) {
      productPrice.value = 'manual';
    }

    setValues({
      inputPrice:
        itemInfo.value?.notVerifyPassedPrice?.defaultPrice ||
        itemInfo.value?.verifyPassedPrice?.defaultPrice ||
        undefined
    });

    tempValue.value = inputPrice.value ? formatMoney(inputPrice.value) : '';

    isDisabled.value = false;

    if (itemInfo.value.notVerifyPassedPrice?.priceList) {
      setItemPriceInfos(
        itemInfo.value?.notVerifyPassedPrice?.priceList.reduce(
          (acc: Record<string, number>, curr: PriceListType) => {
            acc[curr.displayCurrencyCode] = curr.salesPrice;
            return acc;
          },
          {}
        ),
        false
      );
    }

    currencyList.value = currencyList.value.map((it: PriceCurrencyType) => {
      let price = 0;
      let displayPrice = 0;
      if (itemInfo.value?.notVerifyPassedPrice) {
        price =
          itemInfo.value.notVerifyPassedPrice?.priceList?.find(
            (price: PriceListType) => price.displayCurrencyCode === it.displayCurrencyCode
          )?.salesPrice || 0;

        displayPrice =
          itemInfo.value.notVerifyPassedPrice?.priceList?.find(
            (price: PriceListType) => price.displayCurrencyCode === it.displayCurrencyCode
          )?.displayPrice || 0;
      } else if (itemInfo.value?.verifyPassedPrice) {
        price =
          itemInfo.value.verifyPassedPrice?.priceList?.find(
            (price: PriceListType) => price.displayCurrencyCode === it.displayCurrencyCode
          )?.salesPrice || 0;

        displayPrice =
          itemInfo.value.verifyPassedPrice?.priceList?.find(
            (price: PriceListType) => price.displayCurrencyCode === it.displayCurrencyCode
          )?.displayPrice || 0;
      }

      if (price) {
        itemPriceInfos.value[it.displayCurrencyCode] = price;
      }

      return {
        ...it,
        displayPriceValue: displayPrice || 0,
        salesPriceValue: price || 0
      };
    });

    resetForm({
      values: {
        priceSelect: productPrice.value as any
      }
    });
  }
};

const handleChange = (v: string | number) => {
  isFirstTime.value = false;
  if (v === 'manual') {
    isDisabled.value = true;
    return;
  }

  isDisabled.value = false;
};

const handleChangeSalesType = (v: string) => {
  if (v !== SALES_TYPE.FREE) {
    if (Object.values(itemPriceInfos.value).every((it: number) => it === 0)) {
      isDisabled.value = true;
    }
    calculatePaymentPrice();
  }
};

init();

onBeforeRouteLeave(async () => {
  if (isCheckLeave.value) {
    const result = await showConfirmLeaveDialog();
    return result;
  }
});

watch(
  () => errorInput.value,
  () => {
    if (errorInput.value && JSON.parse(errorInput.value).rule === RuleNames.LTE) {
      setValues({
        inputPrice: MAX_VALUE_PRICE_INPUT
      });
      onChangePrice(MAX_VALUE_PRICE_INPUT.toString());
    }
  }
);

const validatePrice = () => {
  if (tempValue.value?.includes(',')) {
    if (tempValue.value.endsWith('.')) {
      setInputPriceError(
        generateErrorMsg(
          'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg2',
          RuleNames.REGEX
        )
      );
      return;
    }

    if (CURRENCY_FORMAT_REGEX.test(tempValue.value)) {
      onBlurPrice();
      return;
    }

    setInputPriceError(
      generateErrorMsg(
        'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg2',
        RuleNames.REGEX
      )
    );
    return;
  }

  onBlurPrice();
};
</script>
